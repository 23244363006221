import type { ReactElement } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { colors, media } from '../../shared/core/styles';
import { FiberRankingTable } from './FiberRankingTable';
import type { ProductFiberRanking as FiberRankingSchema } from '../../../amplienceTypes/schemas/imported/product-fiber-ranking-schema';

const S = {
  Wrapper: styled.div`
    padding: 8px;
    max-width: 1024px;
    margin: 0 auto;
  `,

  Excluded: styled.div`
    margin: 4px 0 0;

    @media ${media.lessThan('md')} {
      margin: 4px;
    }

    background-color: ${colors.FIBER_RANKING_COLOR_BCI};
    padding: 20px;
    font-weight: 500;
    color: ${colors.NERO_GREY};
    font-size: 13px;

    h3 {
      padding-bottom: 10px;
      font-family: 'Gotham HCo', Arial, Helvetica, sans-serif;
      font-weight: 800;
      font-size: 16px;
      line-height: 1.1;
      text-transform: uppercase;
    }
  `,
};

interface FiberRankingDialogProps {
  schemaData: FiberRankingSchema;
}

export const FiberRankingDialog = ({ schemaData }: FiberRankingDialogProps): ReactElement => {
  const { t } = useTranslation('pdp', { keyPrefix: 'productFiberRanking' });

  return (
    <S.Wrapper data-testid="product-fiber-ranking-overlay">
      <FiberRankingTable schemaData={schemaData} />
      <S.Excluded>
        <h3>{schemaData.excludedTitleText || t('excludedTitleText')}</h3>
        {schemaData.excludedDescriptionText || t('excludedDescriptionText')}
      </S.Excluded>
    </S.Wrapper>
  );
};
