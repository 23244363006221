import type { Maybe } from 'graphql/jsutils/Maybe';
import type { ReactElement } from 'react';
import type { Fiber } from '../../../utilities/graphql/codegen';

import { S } from './styles';

export interface FibersProps {
  fibersData: Maybe<Fiber>[];
}
export const Fibers = ({ fibersData }: FibersProps): ReactElement => (
  <S.Fibers.FiberList>
    {fibersData.map((fiber, i) => (
      <S.Fibers.Fiber key={i} data-ranking={fiber?.ranking}>
        <span className="fiber-percentage">{fiber?.percentage}%</span>{' '}
        <strong className="fiber-name">{fiber?.descriptionShort}</strong>{' '}
        <span
          className="fiber-description"
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: fiber?.descriptionLong || '' }}
        />
      </S.Fibers.Fiber>
    ))}
  </S.Fibers.FiberList>
);
