import type { ReactElement } from 'react';

import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import type { Circularity as CircularitySchema } from '../../../amplienceTypes/schemas/imported/product-sustainability-traceability-schema';

import { S } from './styles';

import SustainabilityTraceabilityIcon from '../../shared/core/icons/SustainabilityTraceability';

interface CircularityProps {
  schemaData?: CircularitySchema;
}

export const Circularity = ({ schemaData }: CircularityProps): ReactElement => {
  const {
    product: { sustainability },
  } = usePDPContext();

  const cradleToCradle =
    sustainability?.cradleToCradle &&
    {
      Fabric: schemaData?.cradleToCrade?.fabric,
      Garment: schemaData?.cradleToCrade?.garment,
    }[sustainability?.cradleToCradle];

  const madeToLast = sustainability?.madeToLast && schemaData?.madeToLast && schemaData.madeToLast;

  if (!schemaData || !sustainability || (!cradleToCradle && !madeToLast)) {
    return <></>;
  }

  return (
    <S.Container>
      <S.Header variant="h5" component="h6">
        <SustainabilityTraceabilityIcon type="CIRCULARITY" />
        {schemaData?.title}
      </S.Header>
      {cradleToCradle && <S.Layout dangerouslySetInnerHTML={{ __html: cradleToCradle }} />}
      {madeToLast && <S.Layout dangerouslySetInnerHTML={{ __html: madeToLast }} />}
    </S.Container>
  );
};
