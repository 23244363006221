/* eslint-disable @typescript-eslint/no-empty-function */
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

export type GridValues = {
  gridValue1?: string;
  gridValue2?: string;
};

type SizeContextType = {
  sizeCode: string;
  gridValues: GridValues;
  setSizeCode: (val: string | ((sizeCode: string) => string)) => void;
  setGridValues: (val: GridValues | ((gridValues: GridValues) => GridValues)) => void;
};

const SizeContext = createContext<SizeContextType>({
  sizeCode: '',
  gridValues: {
    gridValue1: undefined,
    gridValue2: undefined,
  },
  setSizeCode: () => {},
  setGridValues: () => {},
});

export const useSizeContext = () => useContext(SizeContext);

export const SizeProvider = ({ children }: { children: ReactNode }) => {
  const [sizeCode, setSizeCode] = useState('');
  const [gridValues, setGridValues] = useState<GridValues>({
    gridValue1: undefined,
    gridValue2: undefined,
  });

  const value = useMemo(
    () => ({
      sizeCode,
      gridValues,
      setSizeCode,
      setGridValues,
    }),
    [sizeCode, gridValues]
  );

  return <SizeContext.Provider value={value}>{children}</SizeContext.Provider>;
};
