import type { ReactElement, MouseEvent } from 'react';
import { useMemo, useState } from 'react';

import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';

import { Dialog } from '../../shared/core/dialog/Dialog';
import { breakpoints, colors } from '../../shared/core/styles';
import { FiberRankingDialog } from '../fiberRanking/FiberRankingDialog';

import type { PreferredMaterials as PreferredMaterialsSchema } from '../../../amplienceTypes/schemas/imported/product-sustainability-traceability-schema';

import { S } from './styles';

import SustainabilityTraceabilityIcon from '../../shared/core/icons/SustainabilityTraceability';
import { Fibers } from './Fibers';

interface PreferredMaterialsProps {
  schemaData?: PreferredMaterialsSchema;
}

export const MINIMUM_SCORE = 2;

export const PreferredMaterials = ({ schemaData }: PreferredMaterialsProps): ReactElement => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    configuration: { fiberRanking: fiberRankingEnabled },
  } = useStaticContext();
  const {
    product: { fiberRanking },
  } = usePDPContext();

  const fibersData = useMemo(
    () =>
      [...(fiberRanking?.fiberRanking?.productFiberRankingData?.fibers ?? [])]
        .filter(
          fiber => (fiber?.score ?? 0) >= MINIMUM_SCORE && (fiber?.descriptionLong?.length ?? 0) > 0
        )
        .sort(
          (a, b) => (b?.score ?? 0) - (a?.score ?? 0) || (b?.percentage ?? 0) - (a?.percentage ?? 0)
        ),
    [fiberRanking]
  );

  const openDialog = (event: MouseEvent<HTMLElement>) => {
    if (schemaData?.fiberRankingContent) {
      event.preventDefault();
      setDialogOpen(true);
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  if (
    !schemaData ||
    !fiberRankingEnabled ||
    !fiberRanking?.fiberRanking?.productFiberRankingData ||
    !fibersData.length
  ) {
    return <></>;
  }

  return (
    <S.Container>
      <S.Header variant="h5" component="h6">
        <SustainabilityTraceabilityIcon type="PREFERRED_MATERIAL" />
        {schemaData.title}
      </S.Header>
      <S.PreferredMaterials.Layout>
        <Fibers fibersData={fibersData} />
        <S.PreferredMaterials.Link onClick={openDialog} href={schemaData.link?.url ?? '#'}>
          {schemaData?.link?.label}
        </S.PreferredMaterials.Link>
      </S.PreferredMaterials.Layout>
      {dialogOpen && schemaData?.fiberRankingContent && (
        <Dialog
          ariaLabel="fiber rankings dialog"
          onClose={closeDialog}
          position="top"
          maxWidth={breakpoints.lg}
          overlayColor={colors.FIBER_RANKING_BG}
        >
          <FiberRankingDialog schemaData={schemaData.fiberRankingContent} />
        </Dialog>
      )}
    </S.Container>
  );
};
