import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

type Type = 'PREFERRED_MATERIAL' | 'FACTORY_LIST' | 'CIRCULARITY';

interface TypedIconProps extends IconProps {
  type: Type;
}

const SustainabilityTraceability = ({
  color = '#177C35',
  hoverColor = colors.BLACK,
  type = 'PREFERRED_MATERIAL',
}: TypedIconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    $hoverColor={hoverColor}
  >
    <circle r="15.5" cx="16" cy="16" stroke={color} strokeWidth="1" />
    {(() => {
      switch (type) {
        case 'PREFERRED_MATERIAL':
          return (
            <path
              className="preferredMaterial"
              fill={color}
              d="m19.25 16.31-.28.47c-.32.53-.38.95-.34 1.3.03.4.18.75.38 1.2v.03c.19.42.4.93.46 1.5.06.6-.07 1.25-.5 1.97l-.28.47-.95-.56.29-.47c.32-.53.38-.95.34-1.3a4.08 4.08 0 0 0-.38-1.2v-.03a5 5 0 0 1-.46-1.5c-.06-.6.07-1.25.5-1.97l.28-.47.94.56ZM22.25 16.31l-.28.47c-.32.53-.38.95-.34 1.3.03.4.18.75.38 1.2v.03a5 5 0 0 1 .46 1.5c.06.6-.07 1.25-.5 1.97l-.28.47-.95-.56.29-.47c.32-.53.38-.95.34-1.3a4.08 4.08 0 0 0-.38-1.2v-.03a5 5 0 0 1-.46-1.5c-.06-.6.07-1.25.5-1.97l.28-.47.94.56ZM15.69 19.25l-.47-.28a2.06 2.06 0 0 0-1.3-.34 4.08 4.08 0 0 0-1.23.39c-.42.18-.93.4-1.5.45a3.15 3.15 0 0 1-1.97-.5l-.48-.28.57-.94.47.28c.53.32.95.38 1.3.34.4-.03.75-.18 1.2-.38h.03a5 5 0 0 1 1.5-.46c.6-.06 1.25.07 1.97.5l.47.28-.56.94ZM15.69 22.25l-.47-.28a2.06 2.06 0 0 0-1.3-.34 4.08 4.08 0 0 0-1.23.39c-.42.18-.93.4-1.5.45a3.15 3.15 0 0 1-1.97-.5l-.48-.28.57-.94.47.28c.53.32.95.38 1.3.34.4-.03.75-.18 1.2-.38h.03a5 5 0 0 1 1.5-.46c.6-.06 1.25.07 1.97.5l.47.28-.56.94ZM11.25 9.31l-.28.47c-.32.53-.38.95-.34 1.3.03.4.18.75.38 1.2v.03c.19.42.4.93.46 1.5.06.6-.07 1.25-.5 1.97l-.28.47-.95-.56.29-.47c.32-.53.38-.95.34-1.3a4.08 4.08 0 0 0-.38-1.2v-.03a5 5 0 0 1-.46-1.5c-.06-.6.07-1.25.5-1.97l.28-.47.94.56ZM14.25 9.31l-.28.47c-.32.53-.38.95-.34 1.3.03.4.18.75.38 1.2v.03a5 5 0 0 1 .46 1.5c.06.6-.07 1.25-.5 1.97l-.28.47-.95-.56.29-.47c.32-.53.38-.95.34-1.3a4.09 4.09 0 0 0-.39-1.23c-.18-.42-.4-.93-.45-1.5-.06-.6.07-1.25.5-1.97l.28-.47.94.56ZM22.69 11.25l-.47-.28a2.06 2.06 0 0 0-1.3-.34 4.08 4.08 0 0 0-1.23.39c-.42.18-.93.4-1.5.45a3.15 3.15 0 0 1-1.97-.5l-.48-.28.57-.94.47.28c.53.32.95.38 1.3.34.4-.03.75-.18 1.2-.38h.03a5 5 0 0 1 1.5-.46c.6-.06 1.25.07 1.97.5l.47.28-.56.94ZM22.69 14.25l-.47-.28a2.06 2.06 0 0 0-1.3-.34 4.08 4.08 0 0 0-1.23.39c-.42.18-.93.4-1.5.45a3.15 3.15 0 0 1-1.97-.5l-.48-.28.57-.94.47.28c.53.32.95.38 1.3.34.4-.03.75-.18 1.2-.38h.03a5 5 0 0 1 1.5-.46c.6-.06 1.25.07 1.97.5l.47.28-.56.94Z"
            />
          );
        case 'CIRCULARITY':
          return (
            <path
              className="circularity"
              fill={color}
              d="M13.1 24.37a8.9 8.9 0 0 1-5.65-11.58l-2.17 1.25-.48-.86 3.89-2.25 2.25 3.88-.87.52-1.5-2.6c-.22.5-.38 1-.49 1.53-.1.53-.16 1.06-.16 1.6 0 1.8.54 3.4 1.6 4.8a7.86 7.86 0 0 0 4.1 2.82l-.52.89Zm6-12.53v-1h2.97a7.93 7.93 0 0 0-11.6-.82l-.51-.9c.8-.7 1.7-1.25 2.7-1.65a8.91 8.91 0 0 1 9.95 2.45V7.35h.99v4.5h-4.5Zm.14 14.9-3.9-2.27 2.26-3.86.86.5-1.56 2.66a7.77 7.77 0 0 0 4.96-2.63 7.7 7.7 0 0 0 1.7-7.3h1.05a7.93 7.93 0 0 1 .24 2 8.7 8.7 0 0 1-2.05 5.73 8.68 8.68 0 0 1-5.22 3.07l2.16 1.24-.5.86Z"
            />
          );
        default:
          return (
            <path
              className="WhereIsItMade"
              fill={color}
              d="M16 28c-1.66 0-3.21-.32-4.67-.95a12.17 12.17 0 0 1-6.38-6.38A11.63 11.63 0 0 1 4 16c0-1.66.32-3.21.95-4.67a12.17 12.17 0 0 1 6.38-6.38C12.79 4.32 14.34 4 16 4c1.66 0 3.21.32 4.67.95a12.17 12.17 0 0 1 6.38 6.38c.63 1.46.95 3.01.95 4.67 0 1.66-.32 3.21-.95 4.67a12.17 12.17 0 0 1-6.38 6.38c-1.46.63-3.01.95-4.67.95Zm0-.93c.87-1.1 1.59-2.17 2.14-3.2.55-1.04 1-2.21 1.34-3.53h-6.96A15.55 15.55 0 0 0 16 27.06Zm-1.2-.1a12.17 12.17 0 0 1-1.97-2.95c-.6-1.21-1.04-2.43-1.31-3.68H5.86a11.17 11.17 0 0 0 3.62 4.6 10.42 10.42 0 0 0 5.31 2.03Zm2.4 0c1.96-.19 3.73-.87 5.32-2.04 1.58-1.17 2.79-2.7 3.62-4.59h-5.66c-.39 1.27-.88 2.5-1.48 3.71-.6 1.2-1.2 2.18-1.8 2.92Zm-11.72-7.6h5.83A16.2 16.2 0 0 1 11 16a20.13 20.13 0 0 1 .31-3.38H5.48a11.56 11.56 0 0 0 0 6.76Zm6.8 0h7.44a16.43 16.43 0 0 0 .31-3.37 19.71 19.71 0 0 0-.3-3.38h-7.45a16.43 16.43 0 0 0-.31 3.38 19.71 19.71 0 0 0 .3 3.38Zm8.41 0h5.83a11.56 11.56 0 0 0 0-6.76h-5.83a16.2 16.2 0 0 1 .31 3.39 20.13 20.13 0 0 1-.31 3.38Zm-.2-7.71h5.65a11.12 11.12 0 0 0-3.57-4.6A11.18 11.18 0 0 0 17.2 5c.7.87 1.35 1.9 1.93 3.07a19.39 19.39 0 0 1 1.34 3.59Zm-7.97 0h6.96c-.39-1.38-.87-2.62-1.43-3.71-.56-1.1-1.25-2.1-2.05-3.02-.8.92-1.49 1.93-2.05 3.02a19.93 19.93 0 0 0-1.43 3.7Zm-6.66 0h5.66c.32-1.22.77-2.42 1.34-3.6.58-1.16 1.22-2.19 1.93-3.06-2.07.25-3.86.95-5.38 2.09a11.35 11.35 0 0 0-3.55 4.57Z"
            />
          );
      }
    })()}
  </Svg>
);

export default SustainabilityTraceability;
