import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'next-i18next';
import { colors, media } from '../../shared/core/styles';
import { FiberRankingAccordion } from './FiberRankingAccordion';
import { FiberRankingTableCaption } from './FiberRankingTableCaption';
import type { ProductFiberRanking as FiberRankingSchema } from '../../../amplienceTypes/schemas/imported/product-fiber-ranking-schema';
import type { Fiber } from '../../../utilities/graphql/codegen';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';

export type CellDataType = {
  th: string;
  td: (Fiber | null | undefined)[];
};

const wrapper = css`
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
  border-collapse: separate;
  border-spacing: 4px 0;
  color: ${colors.BLACK};

  th,
  td {
    text-align: left;
    padding: 0 20px 0 10px;
    vertical-align: top;
  }

  ul {
    margin: 0;
    color: currentcolor;
    line-height: inherit;
    padding-left: 1.2em;
  }

  li {
    color: currentcolor;
    line-height: inherit;
    font-weight: 700;
  }

  thead {
    th {
      padding-top: 20px;
      padding-bottom: 20px;
      font-family: 'Gotham HCo', Arial, Helvetica, sans-serif;
      font-weight: 900;
      font-size: 16px;
      line-height: 0.95;
      text-transform: uppercase;
      position: relative;
      overflow: hidden;
    }
  }

  tbody {
    font-family: 'Gotham HCo', Arial, Helvetica, sans-serif;
    font-size: 13px;

    th {
      padding-bottom: 10px;
      font-weight: 400;
      color: ${colors.NERO_GREY};
    }

    td {
      padding-bottom: 30px;
    }
  }
`;

const S = {
  TableWrapper: styled.table`
    ${wrapper};
    display: none;

    @media ${media.greaterThan('md')} {
      display: initial;
    }
  `,

  TableCaption: styled.caption`
    padding: 10px 4px 20px;
    font-size: 13px;

    h2 {
      font-family: 'Gotham HCo', Arial, Helvetica, sans-serif;
      font-weight: 900;
      font-size: 20px;
      line-height: 0.95;
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 1em;

      &::before {
        content: '';
        width: 40px;
        height: 20px;
        float: right;
      }
    }

    p {
      color: ${colors.NERO_GREY};
      text-align: left;
      margin-bottom: 1em;
    }
  `,

  FiberRankingCaptions: styled.div`
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    color: ${colors.NERO_GREY};

    @media ${media.lessThan('sm')} {
      font-size: 10px;
      color: ${colors.ACCESSIBILITY_GREY};
    }

    .Line {
      flex-grow: 4;
      margin: 0 16px;
      border-bottom: 1px solid ${colors.FIBER_RANKING_BORDER};
    }

    .Label {
      flex-grow: 0;
    }
  `,

  ColItem: styled.col`
    width: calc(100% / 5);
    text-align: left;

    &.A {
      background-color: ${colors.FIBER_RANKING_COLOR_A};
    }

    &.B {
      background-color: ${colors.FIBER_RANKING_COLOR_B};
    }

    &.C {
      background-color: ${colors.FIBER_RANKING_COLOR_C};
    }

    &.D {
      background-color: ${colors.FIBER_RANKING_COLOR_D};
    }

    &.E {
      background-color: ${colors.FIBER_RANKING_COLOR_E};
    }
  `,

  AccordionWrapper: styled.div`
    ${wrapper};
    display: none;

    @media ${media.lessThan('md')} {
      display: block;
    }

    color: ${colors.BLACK};

    dl {
      padding: 20px;
      position: relative;
      overflow: hidden;
      margin: 0 0 8px;

      &.A {
        background-color: ${colors.FIBER_RANKING_COLOR_A};
      }

      &.B {
        background-color: ${colors.FIBER_RANKING_COLOR_B};
      }

      &.C {
        background-color: ${colors.FIBER_RANKING_COLOR_C};
      }

      &.D {
        background-color: ${colors.FIBER_RANKING_COLOR_D};
      }

      &.E {
        background-color: ${colors.FIBER_RANKING_COLOR_E};
      }
    }
  `,
};

interface FiberRankingTableProps {
  schemaData: FiberRankingSchema;
}

export const FiberRankingTable = ({ schemaData }: FiberRankingTableProps): ReactElement => {
  const {
    product: { fiberRanking },
  } = usePDPContext();

  const { t } = useTranslation('pdp', { keyPrefix: 'productFiberRanking' });

  const typeOrder = ['NATURAL', 'SYNTHETIC', 'MANMADE', 'ANIMAL'];
  const rankingOrder = ['A', 'B', 'C', 'D', 'E'];

  const tableData: CellDataType[][] = [];

  typeOrder.forEach(type => {
    const fibersByType =
      fiberRanking?.fiberRanking?.genericFiberRankingData?.fibers?.filter(
        fiber => fiber?.type === type
      ) ?? [];

    const cellData: CellDataType[] = [];

    rankingOrder.forEach(ranking => {
      const fibersByRanking = fibersByType.filter(fiberByType => fiberByType?.ranking === ranking);
      const dataByTypeByRanking = {
        th: fibersByRanking.length === 0 ? '' : type,
        td: fibersByRanking,
      };

      cellData.push(dataByTypeByRanking);
    });

    tableData.push(cellData);
  });

  const accordionData: CellDataType[][] = [];

  rankingOrder.forEach(ranking => {
    const fibersByRanking =
      fiberRanking?.fiberRanking?.genericFiberRankingData?.fibers?.filter(
        fiber => fiber?.ranking === ranking
      ) ?? [];

    const cellData: CellDataType[] = [];

    typeOrder.forEach(type => {
      const fibersByType = fibersByRanking.filter(fiberByRank => fiberByRank?.type === type);
      const dataByRankingByType = {
        th: type,
        td: fibersByType,
      };

      if (fibersByType.length > 0) {
        cellData.push(dataByRankingByType);
      }
    });

    accordionData.push(cellData);
  });

  return (
    <>
      <S.TableWrapper>
        <FiberRankingTableCaption showSwatches={false} schemaData={schemaData} />
        <colgroup>
          {['A', 'B', 'C', 'D', 'E'].map((item, index) => (
            <S.ColItem className={item} key={index} />
          ))}
        </colgroup>
        <thead>
          <tr>
            <th data-ranking="A">{schemaData.rankingA || t('rankingA')}</th>
            <th data-ranking="B">{schemaData.rankingB || t('rankingB')}</th>
            <th data-ranking="C">{schemaData.rankingC || t('rankingC')}</th>
            <th data-ranking="D">{schemaData.rankingD || t('rankingD')}</th>
            <th data-ranking="E">{schemaData.rankingE || t('rankingE')}</th>
          </tr>
        </thead>
        {tableData.map((data, dataIndex) => (
          <tbody key={dataIndex}>
            <tr>
              {data.map((cellData, cellDataIndex) => (
                <th key={cellDataIndex}>{cellData.th}</th>
              ))}
            </tr>
            <tr>
              {data.map((cellData, cellDataIndex) => (
                <td key={cellDataIndex}>
                  <ul>
                    {cellData.td.map((listItem, listItemIndex) => (
                      <li key={listItemIndex}>{listItem?.descriptionShort}</li>
                    ))}
                  </ul>
                </td>
              ))}
            </tr>
          </tbody>
        ))}
      </S.TableWrapper>
      <S.AccordionWrapper>
        <FiberRankingTableCaption schemaData={schemaData} asCaption={false} />
        {accordionData.map((data, dataIndex) => (
          <FiberRankingAccordion
            key={dataIndex}
            data={data}
            rankingOrder={rankingOrder}
            dataIndex={dataIndex}
            schemaData={schemaData}
          />
        ))}
      </S.AccordionWrapper>
    </>
  );
};
