import type { ReactElement } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { media, colors, animations } from '../../shared/core/styles';
import { debounce } from '../../../utilities/parsers';
import {
  componentEasing,
  calloutEasing,
  calloutDuration,
  calloutLabelDelay,
  animationStartDelay,
} from '../../../utilities/constants';
import { getParentElementByDataAttribute } from '../../../utilities/dom';
import { useLayoutDesignContext } from '../../../utilities/context/dynamic/LayoutDesignContext';

interface CoordinateProps {
  x1?: number;
  x2?: number;
  y1?: number;
  y2?: number;
}

export interface TranslateComponentLineProps {
  duration?: number;
  ease?: string;
  attr?: CoordinateProps;
}

const SvgContainer = styled.div<{ $isLoading: boolean; $hasProductAccordions?: boolean }>`
  width: 100%;
  height: 80vw;
  order: 1;
  display: block;

  svg {
    width: 100%;
    height: 100%;
  }

  #callouts {
    display: none;
  }

  @media ${media.greaterThan('sm')} {
    order: 2;
    width: ${({ $hasProductAccordions }) => ($hasProductAccordions ? '100%' : '114%')};
    height: 100%;

    #callouts {
      display: block;
    }
  }

  &::before {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '';
    background-image: var(--spinner);
    background-repeat: no-repeat;
    animation: ${animations.spin} 0.6s linear infinite;
    left: 71.5%;
    display: ${({ $isLoading }) => !$isLoading && 'none'};

    @media ${media.lessThan('sm')} {
      left: 50%;
    }

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }
`;

const timeLine = gsap.timeline();

timeLine.delay(animationStartDelay);
timeLine.pause();

export const ExplodedViewSvg = ({
  documentElement,
  isLoading,
}: {
  documentElement?: HTMLElement;
  isLoading: boolean;
}): ReactElement => {
  const { hasProductAccordions } = useLayoutDesignContext();

  const svgRef = useRef<HTMLDivElement>(null);
  const svgQuery = gsap.utils.selector(svgRef);

  const setupFont = useCallback(() => {
    gsap.to(svgQuery('text'), { fontSize: 12, fontWeight: 700, color: colors.NERO_GREY });
  }, [svgQuery]);

  const setupCalloutAnimations = useCallback(() => {
    const calloutElements = svgQuery('.callout');

    calloutElements.forEach(elm => {
      const delay = parseFloat(elm.dataset.delay ?? '0');
      const duration = parseFloat(elm.dataset.duration ?? calloutDuration);
      const { direction } = elm.dataset;
      let x = 0;
      let y = 0;

      switch (direction) {
        case 'top':
          y = 10;
          break;
        case 'right':
          x = -10;
          break;
        case 'bottom':
          y = -10;
          break;
        default:
          x = 10;
          break;
      }

      if (x || y) {
        const calloutFrom = {
          duration,
          x,
          y,
          opacity: 0,
        };

        const calloutTo = {
          duration,
          x: 0,
          y: 0,
          opacity: 1,
          ease: calloutEasing,
        };

        timeLine.fromTo(elm, calloutFrom, calloutTo, delay);
        timeLine.fromTo(
          elm.querySelectorAll('text'),
          { ...calloutFrom, duration: duration - calloutLabelDelay },
          { ...calloutTo, duration: duration - calloutLabelDelay },
          delay + calloutLabelDelay
        );
      }
    });
  }, [svgQuery]);

  const setupFadeAnimations = useCallback(() => {
    const fadeElements = svgQuery('[data-fade]');

    fadeElements.forEach(elm => {
      const duration = parseFloat(elm.dataset.duration ?? '0');
      const delay = parseFloat(elm.dataset.delay ?? '0');
      const animationType = elm.dataset.fade;
      const isFadeIn = animationType === 'in';

      const opacityComponentFrom = { duration, opacity: isFadeIn ? 0 : 1 };
      const opacityComponentTo = {
        duration,
        opacity: isFadeIn ? 1 : 0,
        ease: componentEasing,
      };

      timeLine.fromTo(elm, opacityComponentFrom, opacityComponentTo, delay);
    });
  }, [svgQuery]);

  const setupAnimations = useCallback(() => {
    const translateElements = svgQuery('[data-translate]');

    translateElements.forEach(elm => {
      const translate = elm.dataset.translate?.toString().split(',') || [];
      const x = parseInt(translate[0] || '0', 10);
      const y = parseInt(translate[1] || '0', 10);
      const hDirection = x > 0 ? 'left' : 'right';
      const vDirection = y > 0 ? 'top' : 'bottom';
      const duration = parseFloat(elm.dataset.duration ?? '0');
      const delay = parseFloat(elm.dataset.delay ?? '0');
      const translateComponentFrom = { duration, x, y };
      const translateComponentTo = { duration, x: 0, y: 0, ease: componentEasing };

      timeLine.fromTo(elm, translateComponentFrom, translateComponentTo, delay);
      Array.from(elm.children).forEach(childElm => {
        if (childElm.nodeName.toLowerCase() !== 'line') {
          return;
        }

        const x1 = parseFloat(childElm.getAttribute('x1') || '0');
        const x2 = parseFloat(childElm.getAttribute('x2') || '0');
        const y1 = parseFloat(childElm.getAttribute('y1') || '0');
        const y2 = parseFloat(childElm.getAttribute('y2') || '0');

        const translateComponentLineFrom: TranslateComponentLineProps = { duration };

        if (hDirection === 'left') {
          translateComponentLineFrom.attr = x1 > x2 ? { x1: x2 } : { x2: x1 };
        } else {
          translateComponentLineFrom.attr = x1 > x2 ? { x2: x1 } : { x1: x2 };
        }

        if (y1 !== y2) {
          if (vDirection === 'top') {
            translateComponentLineFrom.attr = y1 > y2 ? { y1: y2 } : { y2: y1 };
          } else {
            translateComponentLineFrom.attr = y1 > y2 ? { y2: y1 } : { y1: y2 };
          }
        }

        const translateComponentLineFromTween = {
          duration,
          attr: {
            x1: translateComponentLineFrom.attr.x1 ?? x1,
            x2: translateComponentLineFrom.attr.x2 ?? x2,
            y1: translateComponentLineFrom.attr.y1 ?? y1,
            y2: translateComponentLineFrom.attr.y2 ?? y2,
          },
        };

        const translateComponentLineToTween = {
          duration,
          attr: { x1, x2, y1, y2 },
          ease: componentEasing,
        };

        timeLine.fromTo(
          childElm,
          translateComponentLineFromTween,
          translateComponentLineToTween,
          delay
        );
      });
    });

    setupCalloutAnimations();
    setupFadeAnimations();
  }, [setupCalloutAnimations, setupFadeAnimations, svgQuery]);

  const startAnimation = () => {
    timeLine.timeScale(1);
    timeLine.play();
  };

  const resetAnimation = () => {
    timeLine.timeScale(3);
    timeLine.reverse();
  };

  const onIntersectionChange = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries.length) {
        const entry = entries[0];

        if (hasProductAccordions) {
          if (entry.intersectionRect.height) {
            startAnimation();
          } else {
            resetAnimation();
          }

          return;
        }

        if (entry.isIntersecting) {
          startAnimation();
        } else if (entry.boundingClientRect.bottom < 0) {
          resetAnimation();
        }
      }
    },
    [hasProductAccordions]
  );

  const scaleFont = useCallback(() => {
    if (svgRef.current) {
      const svgViewBox = svgRef.current?.getAttribute('viewBox')?.split(/\s/);
      const domRect = svgRef.current?.getBoundingClientRect();
      const fontSizeValue =
        window.getComputedStyle(svgRef.current).getPropertyValue('font-size') || '12px';
      const fontSize = parseInt(fontSizeValue, 10);

      if (svgViewBox && domRect) {
        const svgNaturalHeight = parseInt(svgViewBox[3], 10) - parseInt(svgViewBox[1], 10);

        let scale = (svgNaturalHeight / domRect.height) * 100;
        let y = (svgNaturalHeight - fontSize) / (domRect.height - fontSize);

        if (scale < 100) {
          scale = 100;
          y = 0;
        }

        svgRef.current.style.fontSize = `${scale}%`;

        const textGroups = [...svgQuery('text')];

        textGroups.forEach(el => {
          if (el) {
            el.setAttribute('transform', `translate(0, ${-y})`);
          }
        });
      }
    }
  }, [svgQuery]);

  const setupSvgElement = useCallback(
    (htmlElement: HTMLElement) => {
      svgRef.current?.appendChild(htmlElement);
      setupFont();
      setupAnimations();
    },
    [setupAnimations, setupFont]
  );

  useEffect(() => {
    const scrollObserver = new IntersectionObserver(onIntersectionChange, {
      root: hasProductAccordions
        ? getParentElementByDataAttribute('accordion', svgRef.current)
        : null,
      rootMargin: '0px 0px -50% 0px',
    });
    const debouncedScaleFont = debounce(scaleFont, 100);
    const ref = svgRef.current;

    if (ref) {
      scrollObserver.observe(ref);
      window.addEventListener('resize', debouncedScaleFont);
    }

    return () => {
      if (ref) {
        scrollObserver.unobserve(ref);
        window.removeEventListener('resize', debouncedScaleFont);
      }
    };
  }, [hasProductAccordions, onIntersectionChange, scaleFont]);

  useEffect(() => {
    const ref = svgRef.current;

    try {
      if (
        documentElement &&
        documentElement.nodeName.toLowerCase() === 'svg' &&
        ref?.childNodes.length === 0
      ) {
        if (!isLoading) {
          setupSvgElement(documentElement);
        }
      }
    } catch (error) {}
  }, [documentElement, isLoading, setupSvgElement]);

  return (
    <SvgContainer
      ref={svgRef}
      data-testid="exploding-view-image"
      $isLoading={isLoading}
      $hasProductAccordions={hasProductAccordions}
    />
  );
};
