import type { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { colors, media } from '../../shared/core/styles';
import { FiberRankingLegendSwatches } from './FiberRankingLegendSwatches';
import type { ProductFiberRanking as FiberRankingSchema } from '../../../amplienceTypes/schemas/imported/product-fiber-ranking-schema';

const S = {
  TableCaption: styled.caption`
    padding: 20px 4px;
    font-size: 13px;

    h2 {
      font-family: 'Gotham HCo', Arial, Helvetica, sans-serif;
      font-weight: 900;
      font-size: 20px;
      line-height: 0.95;
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 1em;

      &::before {
        content: '';
        width: 40px;
        height: 20px;
        float: right;
      }
    }

    p {
      color: ${colors.NERO_GREY};
      text-align: left;
      margin-bottom: 1em;
    }
  `,

  FiberRankingCaptions: styled.div`
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    color: ${colors.NERO_GREY};

    @media ${media.lessThan('sm')} {
      font-size: 10px;
      color: ${colors.ACCESSIBILITY_GREY};
    }

    .Line {
      flex-grow: 4;
      margin: 0 16px;
      border-bottom: 1px solid ${colors.FIBER_RANKING_BORDER};
    }

    .Label {
      flex-grow: 0;
    }
  `,
};

interface FiberRankingTableCaptionProps {
  showSwatches?: boolean;
  schemaData: FiberRankingSchema;
  asCaption?: boolean;
}

export const FiberRankingTableCaption = ({
  showSwatches = true,
  schemaData,
  asCaption = true,
}: FiberRankingTableCaptionProps): ReactElement => {
  const { t } = useTranslation('pdp', { keyPrefix: 'productFiberRanking' });

  return (
    <S.TableCaption as={asCaption ? 'caption' : 'div'}>
      <h2>{schemaData.dalogTitleText || t('dalogTitleText')}</h2>
      <p>{schemaData.dialogDescriptionText || t('dialogDescriptionText')}</p>
      <S.FiberRankingCaptions>
        <div className="Label">{schemaData.lowestLongText || t('lowestLongText')}</div>
        <div className="Line" />
        <div className="Label">{schemaData.highestLongText || t('highestLongText')}</div>
      </S.FiberRankingCaptions>
      {showSwatches && <FiberRankingLegendSwatches />}
    </S.TableCaption>
  );
};
