import type { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../shared/core/styles';

const S = {
  Swatches: styled.div`
    position: relative;
    display: flex;
    margin-bottom: 4px;
    margin-top: 5px;
  `,

  Swatch: styled.div`
    min-height: 6px;
    flex-grow: 1;
    margin-right: 1px;

    &.A {
      background-color: ${colors.FIBER_RANKING_COLOR_A};
    }

    &.B {
      background-color: ${colors.FIBER_RANKING_COLOR_B};
    }

    &.C {
      background-color: ${colors.FIBER_RANKING_COLOR_C};
    }

    &.D {
      background-color: ${colors.FIBER_RANKING_COLOR_D};
    }

    &.E {
      background-color: ${colors.FIBER_RANKING_COLOR_E};
    }
  `,
};

interface FiberRankingLegendSwatchesProps {
  children?: ReactNode;
}

export const FiberRankingLegendSwatches = ({
  children,
}: FiberRankingLegendSwatchesProps): ReactElement => (
  <S.Swatches>
    {['A', 'B', 'C', 'D', 'E'].map((item, index) => (
      <S.Swatch className={item} key={index} />
    ))}
    {children}
  </S.Swatches>
);
