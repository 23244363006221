import type { ReactElement } from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'next-i18next';
import type { CellDataType } from './FiberRankingTable';
import { colors } from '../../shared/core/styles';
import type { ProductFiberRanking as FiberRankingSchema } from '../../../amplienceTypes/schemas/imported/product-fiber-ranking-schema';

interface FiberRankingAccordionProps {
  data: CellDataType[];
  rankingOrder: string[];
  dataIndex: number;
  schemaData: FiberRankingSchema;
}

const S = {
  ClickableTitle: styled.dt<{ $isActive: boolean }>`
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Gotham HCo', Arial, Helvetica, sans-serif;
    font-weight: 800;
    font-size: 16px;
    line-height: 1.1;
    text-transform: uppercase;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 15px;
      bottom: 15px;
    }

    &::before {
      height: 40px;
      width: 40px;
      margin-right: -15px;
      margin-bottom: -15px;
      background-color: ${colors.FIBER_RANKING_TITLE};
    }

    &::after {
      display: inline-block;
      border-style: solid;
      border-width: 2px 2px 0 0;
      border-color: currentcolor;
      height: 8px;
      width: 8px;
      margin-left: -4px;
      will-change: transform;
      transform: rotate(135deg);
      transition: transform 0.5s ease-in-out;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    ${({ $isActive }) =>
      $isActive &&
      css`
        &::after {
          transform: rotate(315deg);
        }
      `}
  `,

  ItemsWrapper: styled.div<{ $isActive: boolean }>`
    max-height: ${({ $isActive }) => ($isActive ? '500px' : 0)};
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  CellData: styled.dd<{ $isActive: boolean }>`
    font-size: 13px;
    margin: 0;
    margin-top: 10px;
  `,

  CellDataList: styled.ul`
    font-size: 13px;
    padding: 10px 0;
  `,
};

export const FiberRankingAccordion = ({
  data,
  rankingOrder,
  dataIndex,
  schemaData,
}: FiberRankingAccordionProps): ReactElement => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation('pdp', { keyPrefix: 'productFiberRanking' });

  const accordionClicked = () => {
    setExpanded(!expanded);
  };

  return (
    <dl className={rankingOrder[dataIndex]}>
      <S.ClickableTitle $isActive={expanded} onClick={accordionClicked}>
        {
          (schemaData[`ranking${rankingOrder[dataIndex]}`] ||
            t(`ranking${rankingOrder[dataIndex]}`)) as string
        }
      </S.ClickableTitle>
      <S.ItemsWrapper $isActive={expanded}>
        {data.map((cellData, cellDataIndex) => (
          <S.CellData key={cellDataIndex} $isActive={expanded}>
            {cellData.th}
            <S.CellDataList>
              {cellData.td.map((listItem, listItemIndex) => (
                <li key={listItemIndex}>{listItem?.descriptionShort}</li>
              ))}
            </S.CellDataList>
          </S.CellData>
        ))}
      </S.ItemsWrapper>
    </dl>
  );
};
