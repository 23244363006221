/* eslint-disable react/no-danger */
import type { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { WashingLabels } from '../productInfo/WashingLabels';
import { colors } from '../../shared/core/styles';

const S = {
  Wrapper: styled.div`
    h3 {
      font-size: 14px;
      line-height: 26px;
      font-weight: 700;
      margin: 8px 0;
      margin-bottom: 0;
    }

    p:not(:last-of-type) {
      margin-bottom: 8px;
    }
  `,

  List: styled.ul`
    padding: 0;
    margin: 0 0 13px 16px;
    line-height: 20px;

    a {
      color: ${colors.NERO_BLACK};
      text-decoration: underline;

      &:hover,
      &:focus {
        color: ${colors.NERO_GREY};
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
};

interface ProductDescriptionCareProps {
  title: string;
  materialsTitle: string;
  cleverCareLink: string;
}

export const ProductDescriptionCare = ({
  title,
  materialsTitle,
  cleverCareLink,
}: ProductDescriptionCareProps): ReactElement => {
  const { t } = useTranslation('pdp', { keyPrefix: 'productInfo' });

  const {
    product: {
      featuresIntro,
      detailsBullets,
      programTitle,
      programDescription,
      fabricBullets,
      colorFinishTitle,
      colorFinishDescription,
      washingLabels,
      code,
    },
  } = usePDPContext();

  return (
    <S.Wrapper>
      <h2 data-accordion-title>{title}</h2>
      <p>{featuresIntro}</p>
      {Array.isArray(detailsBullets) && Boolean(detailsBullets.length) && (
        <S.List>
          {detailsBullets.map((e, i) => (
            <li key={i} dangerouslySetInnerHTML={{ __html: e || '' }} />
          ))}
        </S.List>
      )}
      {programTitle && <h3>{programTitle}</h3>}
      {programDescription && <p>{programDescription}</p>}
      {materialsTitle && <h3>{materialsTitle}</h3>}
      {Array.isArray(fabricBullets) && Boolean(fabricBullets.length) && (
        <S.List>
          {fabricBullets.map((e, i) => (
            <li key={i} dangerouslySetInnerHTML={{ __html: e || '' }} />
          ))}
        </S.List>
      )}
      {colorFinishTitle && <h3>{colorFinishTitle}</h3>}
      {colorFinishDescription && <p>{colorFinishDescription}</p>}
      {Array.isArray(washingLabels) && Boolean(washingLabels.length) && (
        <WashingLabels title={t('washingInstructionsTitle')} cleverCareLink={cleverCareLink} />
      )}
      <p>
        {t('artNr')}: {code}
      </p>
    </S.Wrapper>
  );
};
