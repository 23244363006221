export const sizeCategoryIdsMappings: {
  [k: string]: {
    [k: string]: string[];
  };
} = {
  men: {
    bottoms: [
      'men/men_jeans',
      'men/men_shorts',
      'men/men_pants',
      'men/men_jumpsuits',
      'men/men_swimwear',
      'men/men_underwear',
    ],
    tops: [
      'men/men_t-shirts',
      'men/men_polos',
      'men/men_sweats',
      'men/men_shirts',
      'men/men_overshirt',
      'men/men_jackets',
      'men/men_knitwear',
      'men/men_basictees',
    ],
    shoes: ['men/men_shoes'],
    belts: ['men/men_accessories'],
  },
  women: {
    bottoms: [
      'women/women_jeans',
      'women/women_shorts',
      'women/women_pants',
      'women/women_jumpsuits',
      'women/women_skirts',
    ],
    tops: [
      'women/women_shirts',
      'women/women_t-shirts',
      'women/women_sweats',
      'women/women_jackets',
      'women/women_knitwear',
      'women/women_basictees',
      'women/women_overshirt',
      'women/women_dresses',
    ],
    shoes: ['women/women_shoes'],
    belts: ['women/women_accessories'],
  },
};
