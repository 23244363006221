import type { ReactElement } from 'react';

import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import type { PlasticMicroFiber as PlasticMicroFiberSchema } from '../../../amplienceTypes/schemas/imported/product-sustainability-traceability-schema';

import { S } from './styles';

interface PlasticMicroFiberProps {
  schemaData?: PlasticMicroFiberSchema;
}

export const PlasticMicroFiber = ({ schemaData }: PlasticMicroFiberProps): ReactElement => {
  const {
    product: { sustainability },
  } = usePDPContext();

  if (!schemaData || !sustainability?.presenceOfPlasticMicroFiber) {
    return <></>;
  }

  return (
    <S.Container>
      <S.Layout $size="small" dangerouslySetInnerHTML={{ __html: schemaData }} />
    </S.Container>
  );
};
