import type { ComponentType, ReactElement } from 'react';
import { useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';
import type { Maybe } from 'graphql/jsutils/Maybe';
import { Typography } from '../../shared/core/typography/Typography';
import { colors } from '../../shared/core/styles';
import LogoCleverCare from '../../shared/core/icons/LogoCleverCare';
import { toPascalCase, toKebabCase } from '../../../utilities/parsers';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { useLayoutDesignContext } from '../../../utilities/context/dynamic/LayoutDesignContext';
import type { WashingLabel } from '../../../utilities/graphql/codegen';

interface WashingLabelProps {
  title?: string;
  cleverCareLink?: string;
}

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Title: styled(Typography)<{ $hasProductAccordions?: boolean }>`
    ${({ $hasProductAccordions }) =>
      $hasProductAccordions &&
      css`
        text-transform: initial;
        margin-bottom: 0 !important;
      `}
  `,

  IconsContainer: styled.ul`
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
    margin-bottom: 0;
    padding-left: 0;
  `,

  Icon: styled.li`
    list-style: none;
    width: 45px;
    height: 34px;
    padding: 0 4px;
    padding-bottom: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    border-bottom: 2px solid ${colors.BORDER_GREY};

    &[data-selected='true'] {
      padding-bottom: 0;
      border-bottom: 4px solid ${colors.SIGNINGS_BLUE};
    }
  `,

  WashingInstruction: styled(Typography)<{ $hasProductAccordions?: boolean }>`
    margin: 2px 0 13px;
    ${({ $hasProductAccordions }) =>
      !$hasProductAccordions &&
      css`
        height: 80px;
        min-height: 60px;
      `};
    line-height: 20px;
    word-break: break-all;
  `,

  WashingLabel: styled.span`
    display: none;

    &[data-active='true'] {
      display: initial;
    }
  `,

  CleverCareIcon: styled.a<{ $hasProductAccordions?: boolean }>`
    height: 30px;
    width: 100px;
    margin-bottom: ${({ $hasProductAccordions }) => ($hasProductAccordions ? '14px' : '20px')};
  `,
};

export const WashingLabels = ({ title, cleverCareLink }: WashingLabelProps): ReactElement => {
  const [hoverIndex, setHoverIndex] = useState(0);
  const { language } = useAppContext();
  const {
    product: { washingLabels },
  } = usePDPContext();
  const { hasProductAccordions } = useLayoutDesignContext();

  const mouseEnter = (index: number) => {
    setHoverIndex(index);
  };

  const mouseLeave = () => {
    setHoverIndex(0);
  };

  const washingLabelsWithIcons: Maybe<Array<Maybe<WashingLabel> & { icon: Maybe<ComponentType> }>> =
    useMemo(
      () =>
        washingLabels?.map(washingLabel => ({
          ...washingLabel,
          icon: washingLabel?.iconCssClass
            ? dynamic(
                async () =>
                  import(`../../shared/core/icons/${toPascalCase(washingLabel.iconCssClass || '')}`)
              )
            : null,
        })),
      [washingLabels]
    );

  return (
    <S.Wrapper>
      <S.Title
        className="washing-labels-title"
        component="h3"
        variant="h2"
        testId="washing-header"
        $hasProductAccordions={hasProductAccordions}
      >
        {title}
      </S.Title>
      <S.IconsContainer>
        {washingLabelsWithIcons?.map(({ icon: CareIcon, label }, index) =>
          CareIcon ? (
            <S.Icon
              key={index}
              aria-labelledby={`washing-label-${index}-${toKebabCase(label || '')}`}
              data-testid="washing-icon"
              data-selected={hoverIndex === index}
              tabIndex={0}
              onMouseEnter={() => mouseEnter(index)}
              onFocus={() => mouseEnter(index)}
              onBlur={mouseLeave}
              {...(!hasProductAccordions && { onMouseLeave: mouseLeave })}
            >
              <CareIcon />
            </S.Icon>
          ) : (
            <></>
          )
        )}
      </S.IconsContainer>
      <S.WashingInstruction
        testId="washing-instruction"
        component="div"
        variant="body"
        $hasProductAccordions={hasProductAccordions}
      >
        {washingLabelsWithIcons?.map(({ label }, index) => (
          <S.WashingLabel
            data-active={hoverIndex === index}
            key={index}
            id={`washing-label-${index}-${toKebabCase(label || '')}`}
          >
            {label}
          </S.WashingLabel>
        ))}
      </S.WashingInstruction>
      {cleverCareLink && (
        <S.CleverCareIcon
          href={`${cleverCareLink}/${language}`}
          data-testid="clevercare-logo"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Clevercare"
          $hasProductAccordions={hasProductAccordions}
        >
          <LogoCleverCare />
        </S.CleverCareIcon>
      )}
    </S.Wrapper>
  );
};
