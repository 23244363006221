/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import type { ReactNode } from 'react';
import type { SizeGuide, SizeGuides } from '../../graphql/codegen';
import { SizeGuideUnit, SizeType } from '../../graphql/codegen';
import { xmlToJson } from '../../parsers';
import { usePDPContext } from '../static/PDPContext';
import { sizeCategoryIdsMappings } from '../../constants/sizeCategoryIdsMappings';
import { useStaticContext } from '../static/StaticContext';
import { useSizeContext } from './SizeContext';
import { usePDPDynamicContext } from './PDPDynamicContext';

export interface SizeTableProps {
  table: {
    tbody: {
      tr: {
        [key: string]: {
          '#text': string;
        }[];
      }[];
    };
  };
}

export interface SelectedRowProps {
  headerRow: {
    '#text': string;
  }[];
  prevRow: {
    '#text': string;
  }[];
  selectedRow: {
    '#text': string;
  }[];
}

type SizeGuidesContextType = {
  genderIndex: number;
  setGenderIndex: (index: number) => void;
  genderIsExpanded: boolean;
  setGenderIsExpanded: (isExpanded: boolean) => void;
  categoryIndex: number;
  setCategoryIndex: (index: number) => void;
  categoryIsExpanded: boolean;
  setCategoryIsExpanded: (isExpanded: boolean) => void;
  unit: SizeGuideUnit;
  setUnit: (unit: SizeGuideUnit) => void;
  selectionIndexArray: number[];
  setSelectionIndexArray: (arr: number[]) => void;
  selectedRows: SelectedRowProps[];
  setSelectedRows: (rows: SelectedRowProps[]) => void;
  sizeDataList: SizeGuide[];
  setSizeDataList: (list: SizeGuide[]) => void;
  sizeSelectionNavIndex: number;
  setSizeSelectionNavIndex: (index: number) => void;
  tableJsonData: SizeTableProps[][];
  setTableJsonData: (data: SizeTableProps[][]) => void;
  sizeGuidesData: SizeGuides;
};

const SizeGuidesContext = createContext<SizeGuidesContextType>({
  genderIndex: 0,
  setGenderIndex: () => {},
  genderIsExpanded: true,
  setGenderIsExpanded: () => {},
  categoryIndex: 0,
  setCategoryIndex: () => {},
  categoryIsExpanded: true,
  setCategoryIsExpanded: () => {},
  unit: SizeGuideUnit.Cm,
  setUnit: () => {},
  selectionIndexArray: [],
  setSelectionIndexArray: () => {},
  selectedRows: [],
  setSelectedRows: () => {},
  sizeDataList: [],
  setSizeDataList: () => {},
  sizeSelectionNavIndex: 0,
  setSizeSelectionNavIndex: () => {},
  tableJsonData: [],
  setTableJsonData: () => {},
  sizeGuidesData: {},
});

interface Props {
  children: ReactNode;
  sizeGuidesData: SizeGuides;
}

export const useSizeGuidesContext = () => useContext(SizeGuidesContext);

export const SizeGuidesProvider = ({ children, sizeGuidesData }: Props) => {
  const [genderIndex, setGenderIndex] = useState(0);
  const [genderIsExpanded, setGenderIsExpanded] = useState(true);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [categoryIsExpanded, setCategoryIsExpanded] = useState(true);
  const {
    product: { staticCategoryPathIds, sizeInformation: staticSizeInformation },
  } = usePDPContext();
  const {
    dynamicProductData: { sizeInformation: dynamicSizeInformation },
  } = usePDPDynamicContext();
  const sizeInformation = dynamicSizeInformation ?? staticSizeInformation;
  const {
    configuration: { sizeGuideUnit },
  } = useStaticContext();
  const { gridValues } = useSizeContext();
  const [unit, setUnit] = useState(sizeGuideUnit ?? SizeGuideUnit.Cm);
  const [selectionIndexArray, setSelectionIndexArray] = useState<number[]>([]);
  const [sizeDataList, setSizeDataList] = useState<SizeGuide[]>([]);
  const [sizeSelectionNavIndex, setSizeSelectionNavIndex] = useState(0);
  const [tableJsonData, setTableJsonData] = useState<SizeTableProps[][]>([]);
  const [selectedRows, setSelectedRows] = useState<SelectedRowProps[]>([]);

  useEffect(() => {
    const pathId = staticCategoryPathIds?.split('/') ?? [];
    const genderId = (pathId?.length > 0 && pathId ? pathId[0] : '').toLowerCase();

    sizeGuidesData?.sizeGuides?.forEach((sizeGuide, gIndex) => {
      if (sizeGuide?.code?.toLowerCase() === genderId) {
        setGenderIndex(gIndex);
        sizeGuide?.categories?.forEach((category, cIndex) => {
          const pathIds = sizeCategoryIdsMappings[genderId][category?.code ?? 'men'];

          if (pathIds && pathIds.includes(staticCategoryPathIds ?? '')) {
            setCategoryIndex(cIndex);

            if (category?.code === 'bottoms') {
              if (sizeInformation?.sizeType === SizeType.NumericDouble) {
                setSizeSelectionNavIndex(0);
              } else if (sizeInformation?.sizeType === SizeType.Letters) {
                setSizeSelectionNavIndex(1);
              } else {
                setSizeSelectionNavIndex(2);
              }
            }
          }
        });
      }
    });
  }, [sizeGuidesData?.sizeGuides, sizeInformation?.sizeType, staticCategoryPathIds]);

  useEffect(() => {
    if (sizeDataList.length > 0) {
      const renderingArray = (
        (sizeDataList[genderIndex].categories ?? [])[categoryIndex]?.measuresystems ?? []
      ).map(data => {
        const tableData = data?.tables ?? [];
        const jsonArr = tableData.map(item => {
          const xmlStr = item?.tableHtml ?? '';
          const convertedJson = xmlToJson(
            new DOMParser().parseFromString(xmlStr, 'text/xml')
          ) as SizeTableProps;

          return convertedJson;
        });

        return jsonArr;
      });

      const selectionIndexArr: number[] =
        renderingArray[sizeSelectionNavIndex] &&
        renderingArray[sizeSelectionNavIndex].length === selectionIndexArray.length
          ? selectionIndexArray
          : Array(renderingArray.length).fill(-1);

      if (gridValues.gridValue1) {
        const index = renderingArray[sizeSelectionNavIndex][0].table.tbody.tr.findIndex(
          item =>
            (Array.isArray(item?.th) && item?.th[0]['#text'] === gridValues.gridValue1) ||
            (Array.isArray(item?.td) && item?.td[0]['#text'] === gridValues.gridValue1)
        );

        selectionIndexArr[0] = index;
      }

      if (gridValues.gridValue2 && renderingArray[sizeSelectionNavIndex].length > 1) {
        const index = renderingArray[sizeSelectionNavIndex][1].table.tbody.tr.findIndex(
          item =>
            (Array.isArray(item?.th) && item?.th[0]['#text'] === gridValues.gridValue2) ||
            (Array.isArray(item?.td) && item?.td[0]['#text'] === gridValues.gridValue2)
        );

        selectionIndexArr[1] = index;
      }

      const updatedRows = selectionIndexArr.map((item, index) => {
        if (item === -1) {
          return {
            headerRow: [],
            prevRow: [],
            selectedRow: [],
          };
        }

        const groupItemLength = renderingArray[sizeSelectionNavIndex][index].table.tbody.tr.length;

        if (groupItemLength >= item) {
          const headerRow =
            renderingArray[sizeSelectionNavIndex][index].table.tbody.tr[0]?.th ??
            renderingArray[sizeSelectionNavIndex][index].table.tbody.tr[0]?.td;
          const selectedRow =
            renderingArray[sizeSelectionNavIndex][index].table.tbody.tr[item]?.th ??
            renderingArray[sizeSelectionNavIndex][index].table.tbody.tr[item]?.td;

          return {
            headerRow,
            prevRow: selectedRows[index]?.selectedRow || [],
            selectedRow,
          };
        }

        return {
          headerRow: [],
          prevRow: [],
          selectedRow: [],
        };
      });

      setSelectedRows(updatedRows);
      setSelectionIndexArray(selectionIndexArr);

      setTableJsonData(renderingArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryIndex, genderIndex, sizeDataList, sizeSelectionNavIndex]);

  useEffect(() => {
    const sizeData =
      sizeGuidesData?.sizeGuides?.map(sizeGuide => {
        const categories = (sizeGuide?.categories ?? []).map(category => {
          const measuresystems = (category?.measuresystems ?? [])
            .map(measureItem => {
              const genderClassName = `js-measurementsTable--${(
                sizeGuide?.code ?? ''
              ).toLowerCase()}`;
              const categoryClassName = `js-measurementsTable--${(
                category?.code ?? ''
              ).toLowerCase()}`;
              const unitClassName = `js-measurementsTable--${unit.toLowerCase()}`;

              const tables = (measureItem?.tables ?? []).filter(
                item =>
                  item?.tableHtml?.includes(genderClassName) &&
                  item?.tableHtml?.includes(categoryClassName) &&
                  item?.tableHtml?.includes(unitClassName)
              );

              if (measureItem?.code === 'bottomswl') {
                const addedTableItem = (
                  category?.measuresystems?.find(ms => ms?.code === 'bottomssizewaist')?.tables ??
                  []
                ).filter(
                  item =>
                    item?.tableHtml?.includes(genderClassName) &&
                    item?.tableHtml?.includes(categoryClassName) &&
                    item?.tableHtml?.includes(unitClassName)
                );

                return {
                  code: measureItem?.code,
                  name: measureItem?.name,
                  tables: addedTableItem.concat(tables),
                };
              }

              return {
                code: measureItem?.code,
                name: measureItem?.name,
                tables,
              };
            })
            .filter(measureItem => measureItem.tables.length > 0);

          return {
            code: category?.code,
            descriptions: category?.descriptions,
            measuresystems,
            name: category?.name,
          };
        });

        return {
          code: sizeGuide?.code,
          gender: sizeGuide?.gender,
          name: sizeGuide?.name,
          categories,
        };
      }) ?? [];

    setSizeDataList(sizeData);
  }, [sizeGuidesData?.sizeGuides, unit]);

  const value = useMemo(
    () => ({
      genderIndex,
      setGenderIndex,
      genderIsExpanded,
      setGenderIsExpanded,
      categoryIndex,
      setCategoryIndex,
      categoryIsExpanded,
      setCategoryIsExpanded,
      unit,
      setUnit,
      selectionIndexArray,
      setSelectionIndexArray,
      sizeDataList,
      setSizeDataList,
      sizeSelectionNavIndex,
      setSizeSelectionNavIndex,
      tableJsonData,
      setTableJsonData,
      selectedRows,
      setSelectedRows,
      sizeGuidesData,
    }),
    [
      genderIndex,
      genderIsExpanded,
      categoryIndex,
      categoryIsExpanded,
      unit,
      selectionIndexArray,
      sizeDataList,
      sizeSelectionNavIndex,
      tableJsonData,
      selectedRows,
      sizeGuidesData,
    ]
  );

  return <SizeGuidesContext.Provider value={value}>{children}</SizeGuidesContext.Provider>;
};
