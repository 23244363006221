import Image from 'next/legacy/image';
import dynamic from 'next/dynamic';
import type { PropsWithChildren, ReactElement } from 'react';
import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'next-i18next';
import type { ProductInfo as ProductInfoSchema } from '../../../amplienceTypes/schemas/imported/product-info-schema';
import type { ImageData as ImageDataHybrisType } from '../../../utilities/graphql/codegen';
import { colors, media, typography } from '../../shared/core/styles';
import { Typography } from '../../shared/core/typography/Typography';
import { WashingLabels } from './WashingLabels';
import { Dialog } from '../../shared/core/dialog/Dialog';
import type { WhereIsItMadeProps } from '../whereIsItMade/WhereIsItMade';
import { useHashChanged } from '../../../utilities/hooks';
import { isCloudinaryImage } from '../../../utilities/parsers';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { useLayoutDesignContext } from '../../../utilities/context/dynamic/LayoutDesignContext';
import { imageBlurData } from '../../../utilities/constants/base64Images';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';

const WhereIsItMade = dynamic<WhereIsItMadeProps>(
  () => import('../whereIsItMade/WhereIsItMade').then(mod => mod.WhereIsItMade),
  { ssr: false }
);

const S = {
  Wrapper: styled.div<{ $isOneColumnGrid: boolean }>`
    display: grid;
    grid-template-columns: repeat(1, minmax(200px, 3500px));
    background-color: ${colors.WHITE_SMOKE_GREY};

    @media ${media.greaterThan('sm')} {
      grid-template-columns: ${({ $isOneColumnGrid }) =>
        $isOneColumnGrid ? 'repeat(1, minmax(200px, 3500px))' : 'repeat(2, minmax(200px, 3500px))'};
    }
  `,

  InfoContainer: styled.div<{ $imgIsLeft: boolean }>`
    padding: 8% 0;
    width: 100%;
    display: flex;
    justify-content: center;
    order: ${({ $imgIsLeft }) => ($imgIsLeft ? 2 : 4)};

    @media ${media.greaterThan('sm')} {
      order: ${({ $imgIsLeft }) => ($imgIsLeft ? 2 : 3)};
    }
  `,

  InfoContainerText: styled.div`
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${media.between('sm', 'lg')} {
      padding: 0 10%;
      width: 375px;
      min-height: 560px;
    }

    @media ${media.greaterThan('lg')} {
      width: 420px;
      min-height: 560px;
    }
  `,

  Header: styled(Typography)`
    padding-bottom: 9px;
  `,

  Title: styled(Typography)<{ $hasProductAccordions?: boolean }>`
    margin-bottom: 2px;

    ${({ $hasProductAccordions }) =>
      $hasProductAccordions &&
      css`
        text-transform: initial;
      `}
  `,

  Description: styled.div<{ $hasProductAccordions?: boolean }>`
    ${typography.body}

    color: ${({ $hasProductAccordions }) =>
      $hasProductAccordions ? colors.NERO_BLACK : colors.ACCESSIBILITY_GREY};
    margin-bottom: 13px;

    p {
      ${typography.body}
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  `,

  ContentList: styled.ul`
    margin: 0 0 13px 16px;
    padding-left: 0;
  `,

  ContentItem: styled.li<{ $hasProductAccordions?: boolean }>`
    ${({ $hasProductAccordions }) => $hasProductAccordions && 'line-height: 20px;'}
    color: ${({ $hasProductAccordions }) =>
      $hasProductAccordions ? colors.NERO_BLACK : colors.ACCESSIBILITY_GREY};
    font-size: 13px;

    & > a {
      color: ${({ $hasProductAccordions }) =>
        $hasProductAccordions ? colors.NERO_BLACK : colors.ACCESSIBILITY_GREY};

      &:hover,
      &:focus {
        color: ${colors.NERO_GREY};
      }
    }
  `,

  Sustainability: styled.p`
    font-size: 13px;
    margin-bottom: 13px;
    text-align: left;
    color: ${colors.ACCESSIBILITY_GREY};
    text-decoration: underline;
  `,

  ItemLink: styled.a<{ $hasProductAccordions?: boolean }>`
    color: ${({ $hasProductAccordions }) =>
      $hasProductAccordions ? colors.NERO_BLACK : colors.ACCESSIBILITY_GREY};

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
    }
  `,

  ImageContainer: styled.div<{
    $imgIsLeft: boolean;
    $hasProductAccordions?: boolean;
    $imageHeight: number;
  }>`
    width: 100%;
    ${({ $hasProductAccordions }) => !$hasProductAccordions && 'height: 100vw;'}
    position: relative;
    order: ${({ $imgIsLeft }) => ($imgIsLeft ? 1 : 3)};
    transition: height 250ms ease;

    @media (prefers-reduced-motion) {
      transition: none;
    }

    @media ${media.lessThan('sm')} {
      ${({ $hasProductAccordions }) => !$hasProductAccordions && 'height: 100vw;'};
    }

    @media ${media.lessThan('lg')} {
      ${({ $hasProductAccordions }) => $hasProductAccordions && 'height: 100vw;'};
    }

    @media ${media.greaterThan('sm')} {
      order: ${({ $imgIsLeft }) => ($imgIsLeft ? 1 : 4)};
      ${({ $hasProductAccordions }) => !$hasProductAccordions && 'height: 100%;'}
    }
  `,

  Triangle: styled.div<{ $imgIsLeft: boolean }>`
    border-right: ${({ $imgIsLeft }) =>
      $imgIsLeft ? `20px solid ${colors.WHITE_SMOKE_GREY}` : 'unset'};
    right: ${({ $imgIsLeft }) => ($imgIsLeft ? 0 : 'unset')};
    border-left: ${({ $imgIsLeft }) =>
      $imgIsLeft ? 'unset' : `20px solid ${colors.WHITE_SMOKE_GREY}`};
    left: ${({ $imgIsLeft }) => ($imgIsLeft ? 'unset' : 0)};
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    z-index: 2;
    display: none;

    @media ${media.greaterThan('sm')} {
      display: inline;
    }
  `,

  Image: styled(Image)`
    background-color: ${colors.WHITE};
    object-fit: cover;
    width: 100%;
    height: 100%;
  `,

  CleverCareWrapper: styled.div`
    height: 30px;
    width: 100px;
    margin-bottom: 20px;
  `,

  CleverCareAnchor: styled.a`
    background-color: transparent;
  `,

  ProductCode: styled(Typography)`
    text-transform: uppercase;
  `,
};

export const ProductInfo = ({
  cleverCareLink,
  featuresTitleV2,
  sustainabilityTitleV2,
  sustainabilityLink,
  artNrV2,
  washingInstructionsTitleV2,
  imageAlign = 'Left',
  type = 'Feature',
  employeesTitleV2,
  percentageManTitleV2,
  percentageWomanTitleV2,
  productCategoriesTitleV2,
  factoryInformationTitleV2,
  dimensionsTitle,
}: ProductInfoSchema): ReactElement => {
  const {
    product: {
      colorFinishDescription,
      colorFinishTitle,
      detailsBullets,
      detailsTitle,
      dimensionsBullets,
      fabricHeader,
      fabricTitle,
      fabricBullets,
      fabricDescription,
      featuresIntro,
      simplifiedImages,
      washingLabels: graphqlWashingLabels,
      baseProduct,
      code,
      factoryId,
      isGiftCard,
    },
  } = usePDPContext();
  const { pushToDataLayer } = useDataLayerContext();
  const { hasProductAccordions } = useLayoutDesignContext();
  const { t } = useTranslation('pdp', { keyPrefix: 'productInfo' });

  const images = useMemo(() => {
    const images: {
      [k: string]: ImageDataHybrisType;
    } = {
      M07: { altText: '', url: '' },
      Z07: { altText: '', url: '' },
      M06: { altText: '', url: '' },
      Z06: { altText: '', url: '' },
    };

    simplifiedImages?.entry
      ?.filter(item => item && item.key && ['M07', 'Z07', 'M06', 'Z06'].includes(item.key))
      .forEach(item => {
        if (item && item.value) {
          images[`${item.key}`] = item.value;
        }
      });

    return images;
  }, [simplifiedImages?.entry]);

  const fabricImage = images.M07.url !== '' ? images.M07 : images.Z07;
  const featuresImage = images.M06.url !== '' ? images.M06 : images.Z06;
  const imageHeight = 795;

  const washingLabels = useMemo(
    () =>
      graphqlWashingLabels
        ?.filter(elm => elm?.iconCssClass && elm?.label)
        .map(elm => ({ iconCssClass: elm?.iconCssClass ?? '', label: elm?.label ?? '' })),
    [graphqlWashingLabels]
  );

  const dataLayerEventData = useMemo(
    () => ({
      events: {
        category: 'where is it made',
        action: 'open',
        label: undefined,
      },
      event: 'whereisitmade-open',
    }),
    []
  );

  const { hashMatched, setHashMatched } = useHashChanged(
    ['#csr-map'],
    type === 'Fabric' ? dataLayerEventData : undefined
  );

  const handleOpen = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setHashMatched(true);
      pushToDataLayer(dataLayerEventData);
    },
    [dataLayerEventData, pushToDataLayer, setHashMatched]
  );

  const handleClose = useCallback(() => setHashMatched(false), [setHashMatched]);

  const convertImageUrl = useCallback(
    (originalUrl?: string | null): string =>
      originalUrl ? originalUrl.replace('{{dimensions}}', `h_${imageHeight}`) : '',
    [imageHeight]
  );

  const image = useCallback(
    (img: ImageDataHybrisType, imgIsLeft: boolean, testId: string) => (
      <S.ImageContainer
        className="product-info-image"
        $imgIsLeft={imgIsLeft}
        $hasProductAccordions={hasProductAccordions}
        $imageHeight={imageHeight}
      >
        <S.Image
          src={convertImageUrl(img.url)}
          alt={img.altText || ''}
          layout="fill"
          quality="100"
          data-testid={testId}
          blurDataURL={imageBlurData}
          placeholder="blur"
          unoptimized={isCloudinaryImage(img.url)}
          loading="lazy"
          priority={false}
          lazyBoundary="100px"
        />
        {!hasProductAccordions && <S.Triangle $imgIsLeft={imgIsLeft} />}
      </S.ImageContainer>
    ),
    [convertImageUrl, hasProductAccordions, imageHeight]
  );

  const Container = useMemo(
    () =>
      hasProductAccordions
        ? ({ children }: PropsWithChildren<unknown>) => <div>{children}</div>
        : ({ children, ...props }: PropsWithChildren<unknown> & { $imgIsLeft: boolean }) => (
            <S.InfoContainer {...props}>
              <S.InfoContainerText>{children}</S.InfoContainerText>
            </S.InfoContainer>
          ),
    [hasProductAccordions]
  );

  const featuresInfo = useMemo(
    () => (
      <Container $imgIsLeft>
        <S.Header variant="h1" testId="features-header" component="h2" data-accordion-title>
          {featuresTitleV2 || t('featuresTitle')}
        </S.Header>
        {featuresIntro && (
          <S.Description
            dangerouslySetInnerHTML={{ __html: featuresIntro }}
            data-testid="features-description"
            $hasProductAccordions={hasProductAccordions}
          />
        )}
        {detailsTitle && (
          <S.Title
            component="h3"
            variant="h2"
            testId="features-title"
            $hasProductAccordions={hasProductAccordions}
          >
            {detailsTitle}
          </S.Title>
        )}
        {detailsBullets && (
          <S.ContentList data-testid="features-bullets">
            {detailsBullets.map((bullet, i) => (
              <S.ContentItem key={i}>
                <Typography
                  component="p"
                  variant="body"
                  testId="features-bullets-item"
                  color={hasProductAccordions ? colors.NERO_BLACK : colors.ACCESSIBILITY_GREY}
                >
                  {bullet}
                </Typography>
              </S.ContentItem>
            ))}
          </S.ContentList>
        )}
        {dimensionsTitle && dimensionsBullets && dimensionsBullets.length > 0 && (
          <>
            <S.Title
              component="h3"
              variant="h2"
              testId="features-dimensions-title"
              $hasProductAccordions={hasProductAccordions}
            >
              {dimensionsTitle}
            </S.Title>
            <S.ContentList data-testid="features-dimensions-bullets">
              {dimensionsBullets.map((bullet, i) => (
                <S.ContentItem key={i}>
                  <Typography
                    component="p"
                    variant="body"
                    testId="features-dimensions-bullets-item"
                    color={hasProductAccordions ? colors.NERO_BLACK : colors.ACCESSIBILITY_GREY}
                  >
                    {bullet}
                  </Typography>
                </S.ContentItem>
              ))}
            </S.ContentList>
          </>
        )}
      </Container>
    ),
    [
      Container,
      detailsBullets,
      detailsTitle,
      dimensionsBullets,
      dimensionsTitle,
      featuresIntro,
      featuresTitleV2,
      hasProductAccordions,
      t,
    ]
  );

  const fabricInfo = useMemo(
    () => (
      <Container $imgIsLeft={false} data-qubit-id="art-number">
        {fabricHeader && (
          <S.Header variant="h1" testId="fabrics-header" component="h2" data-accordion-title>
            {fabricHeader}
          </S.Header>
        )}
        {fabricTitle && (
          <S.Title
            component="h3"
            variant="h2"
            testId="fabrics-title"
            $hasProductAccordions={hasProductAccordions}
          >
            {fabricTitle}
          </S.Title>
        )}
        {fabricDescription && (
          <S.Description
            dangerouslySetInnerHTML={{ __html: fabricDescription }}
            data-testid="fabrics-description"
            $hasProductAccordions={hasProductAccordions}
          />
        )}
        {fabricBullets && (
          <S.ContentList data-testid="fabrics-bullets">
            {fabricBullets.map((bullet, i) => (
              <S.ContentItem
                key={i}
                dangerouslySetInnerHTML={{ __html: bullet ?? '' }}
                data-testid="fabrics-bullets-item"
                $hasProductAccordions={hasProductAccordions}
              />
            ))}
          </S.ContentList>
        )}
        {colorFinishTitle && (
          <>
            <S.Title
              component="h3"
              variant="h2"
              testId="fabrics-color-title"
              $hasProductAccordions={hasProductAccordions}
            >
              {colorFinishTitle}
            </S.Title>
            {colorFinishDescription && (
              <S.Description
                dangerouslySetInnerHTML={{ __html: colorFinishDescription }}
                data-testid="fabrics-color-description"
                $hasProductAccordions={hasProductAccordions}
              />
            )}
          </>
        )}
        {sustainabilityLink && factoryId && (
          <>
            <S.Sustainability data-testid="fabrics-manufacture-link">
              <S.ItemLink
                href={sustainabilityLink}
                onClick={handleOpen}
                $hasProductAccordions={hasProductAccordions}
              >
                {sustainabilityTitleV2 || t('sustainabilityTitle')}
              </S.ItemLink>
            </S.Sustainability>
            {hashMatched && (
              <Dialog ariaLabel="where is it made" onClose={handleClose}>
                <WhereIsItMade
                  factoryInfoTitles={{
                    employeesTitle: employeesTitleV2 || t('employeesTitle'),
                    percentageManTitle: percentageManTitleV2 || t('percentageManTitle'),
                    percentageWomanTitle: percentageWomanTitleV2 || t('percentageWomanTitle'),
                    productCategoriesTitle: productCategoriesTitleV2 || t('productCategoriesTitle'),
                    factoryInformationTitle:
                      factoryInformationTitleV2 || t('factoryInformationTitle'),
                  }}
                />
              </Dialog>
            )}
          </>
        )}
        {washingLabels && washingLabels.length > 0 && (
          <WashingLabels
            title={washingInstructionsTitleV2 || t('washingInstructionsTitle')}
            cleverCareLink={cleverCareLink}
          />
        )}
        {!isGiftCard && (
          <Typography component="p" variant="body" testId="art-number">
            {artNrV2 || t('artNr')}:{' '}
            <S.ProductCode component="span" variant="body">
              {baseProduct || code}
            </S.ProductCode>
          </Typography>
        )}
      </Container>
    ),
    [
      Container,
      artNrV2,
      baseProduct,
      cleverCareLink,
      code,
      colorFinishDescription,
      colorFinishTitle,
      employeesTitleV2,
      fabricBullets,
      fabricDescription,
      fabricHeader,
      fabricTitle,
      factoryId,
      factoryInformationTitleV2,
      handleClose,
      handleOpen,
      hasProductAccordions,
      hashMatched,
      isGiftCard,
      percentageManTitleV2,
      percentageWomanTitleV2,
      productCategoriesTitleV2,
      sustainabilityLink,
      sustainabilityTitleV2,
      t,
      washingInstructionsTitleV2,
      washingLabels,
    ]
  );

  // Features info and fabric info should be shown in product accordions
  if (hasProductAccordions) {
    return type === 'Feature' ? (
      <>
        {featuresInfo}
        {featuresImage.url !== '' && image(featuresImage, imageAlign === 'Left', 'features-image')}
      </>
    ) : (
      <>
        {fabricInfo}
        {fabricImage.url !== '' && image(fabricImage, imageAlign === 'Left', 'fabrics-image')}
      </>
    );
  }

  // Features info and fabric info to be in the same section
  if (featuresImage.url === '' && fabricImage.url === '') {
    if (type === 'Feature') {
      if (!(detailsTitle || featuresIntro)) {
        return (
          <S.Wrapper $isOneColumnGrid data-testid={type} data-cs-capture="">
            {fabricInfo}
          </S.Wrapper>
        );
      }

      if (!(fabricHeader || fabricTitle)) {
        return (
          <S.Wrapper $isOneColumnGrid data-testid={type} data-cs-capture="">
            {featuresInfo}
          </S.Wrapper>
        );
      }

      return (
        <S.Wrapper $isOneColumnGrid={false} data-testid={type} data-cs-capture="">
          {featuresInfo}
          {fabricInfo}
        </S.Wrapper>
      );
    }

    return <></>;
  }

  // Features info and fabric info to be in the different section
  return type === 'Feature' ? (
    <S.Wrapper $isOneColumnGrid={featuresImage.url === ''} data-testid={type} data-cs-capture="">
      {featuresImage.url !== '' && image(featuresImage, imageAlign === 'Left', 'features-image')}
      {featuresInfo}
    </S.Wrapper>
  ) : (
    <S.Wrapper $isOneColumnGrid={fabricImage.url === ''} data-testid={type} data-cs-capture="">
      {fabricImage.url !== '' && image(fabricImage, imageAlign === 'Left', 'fabrics-image')}
      {fabricInfo}
    </S.Wrapper>
  );
};
