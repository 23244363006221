import type { ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import { media } from '../../shared/core/styles';
import { buildApiUrl } from '../../../utilities/parsers';
import { useLayoutDesignContext } from '../../../utilities/context/dynamic/LayoutDesignContext';
import { usePDPContext } from '../../../utilities/context/static/PDPContext';
import { ExplodedViewInfo } from './ExplodedViewInfo';
import { ExplodedViewSvg } from './ExplodedViewSvg';
import fetchService from '../../../utilities/helpers/fetchService';

const Wrapper = styled.div`
  padding: 24px;
  display: block;
  position: relative;

  @media ${media.greaterThan('sm')} {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    overflow: hidden;
    padding: 24px 0 0 56px;
  }
`;

export const ExplodedView = (): ReactElement => {
  const [svgHtml, setSvgHtml] = useState<HTMLElement>();
  const [isLoading, setIsLoading] = useState(true);
  const { locale } = useAppContext();
  const {
    product: { explodingView },
  } = usePDPContext();
  const { hasProductAccordions } = useLayoutDesignContext();
  const explodingViewSvgUrl = explodingView?.explodingViewSvgUrl;

  const getSvgSource = useCallback(async () => {
    try {
      if (explodingViewSvgUrl) {
        const url = buildApiUrl(explodingViewSvgUrl, locale);
        const response = await fetchService.get<string>(url);

        const { documentElement } = new window.DOMParser().parseFromString(
          response.data,
          'text/xml'
        );

        setIsLoading(false);
        setSvgHtml(documentElement);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [explodingViewSvgUrl, locale]);

  useEffect(() => {
    if (document.readyState === 'complete') {
      getSvgSource();
    } else {
      window.addEventListener('load', getSvgSource);
    }

    return () => {
      window.removeEventListener('load', getSvgSource);
    };
  }, [getSvgSource]);

  if (!explodingView) {
    return <></>;
  }

  if (hasProductAccordions) {
    return (
      <div data-testid="exploding-view" data-cs-capture="">
        <ExplodedViewSvg documentElement={svgHtml} isLoading={isLoading} />
        <ExplodedViewInfo />
      </div>
    );
  }

  return (
    <Wrapper data-testid="exploding-view" data-cs-capture="">
      <ExplodedViewSvg documentElement={svgHtml} isLoading={isLoading} />
      <ExplodedViewInfo />
    </Wrapper>
  );
};
